/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
.ProductLinks {
    &-Wrapper {
        @include  desktop {
            padding-block-end: 50px;
        }
    }

    &-Title {
        @include mobile {
            text-transform: uppercase;
            text-align: center;
            padding: 0;
            margin-block-end: 10px;
            font-size: 24px;
            line-height: 26px;
        }

        @include desktop {
            font-size: 44px;
            line-height: 48px;
            text-align: center;
            text-transform: inherit;
        }
    }

    &-List {
        margin-block-start: 50px;

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 50px;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-row-gap: 20px;
            grid-column-gap: 20px;
        }
    }
}
