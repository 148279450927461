/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
.ProductAttributes {
    &-ExpandableContentContent {
        @include desktop {
            columns: 1;
        }
    }

    &-Group {
        font-size: 20px;
        
        @include mobile {
            font-size: 16px;
        }
    }
}
