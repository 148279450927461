/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductConfigurableAttributes {
    &-SwatchList,
    &-DropDownList {
        margin-block-end: 20px;

        @include mobile {
            margin-block-start: 20px;
        }

        @include desktop {
            margin-block-start: 24px;
        }
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: 1px solid var(--expandable-content-divider-color);
    }

    &-Expandable.ExpandableContent:first-of-type {
        border-top: 1px solid var(--expandable-content-divider-color);
    }
}
