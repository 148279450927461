/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable selector-pseudo-element-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable declaration-no-important */

.ProductAttributeValue {
    &-Image {
        height: auto;
        width: auto;
        max-height: 55px;
        max-width: 55px;
        margin-inline-end: 0;
        border-radius: unset;
    }

    &-Image,
    &-String {
        background: transparent;
    }

    &_type {
        &_0 {
            width: 60px;
            height: 60px;
            margin-inline-end: 10px;
            line-height: 20px;
            border: 1px solid #E2E4E7;
            border-radius: 10px;
         

            &:first-child {
                font-weight: bold;
            }

            .ProductAttributeValue-String {
                margin: 0;      
                border: 0;  
                line-height: 20px;  
                text-transform: uppercase;  
                color: #6C6F7A;
                font-weight: 500;
                font-size: 16px;

                &::first-line {
                    font-weight: bold;
                }
            }
        }

        &_2 {
            border: 1px solid #E2E4E7;
            border-radius: 10px;
            box-sizing: border-box;
            margin-inline-end: 30px;
            display: flex;
            flex-direction: column;
            font-weight: 500;

            @include mobile {
                width: 82px;
                height: 94px;
            }

            @include desktop {
                width: 90px;
                height: 102px;
            }
        }

        &_0,
        &_2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-block-end: 10px;

            .ProductAttributeValue-Label {
                color: #42444B;
            }
        }
    }

    &_isSelected {
        background-color: #EBFAFE;
    }

    &-Label {
        font-size: 12px;
        line-height: 16px;
        padding-block-start: 5px;
        color: #6C6F7A;
    }

    &-Color,
    &-Image-Overlay {
        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 0;
                }
            }
        }
    }


    &-Text {
        min-height: unset;
        padding: 0;

        @include mobile {
            margin-block-start: 20px;
        }

        @include desktop {
            margin-block-start: 24px;
        }

        label {
            &:first-of-type {
                .input-control {
                    inset-inline-start: 0;
                    width: 24px;
                    height: 24px;
                    min-width: unset;
                    min-height: unset;
                    vertical-align: top;
                    border-color: var(--primary-blue-color);

                    @include desktop {
                        align-self: self-start;
                        margin-block-start: 4px;
                    }
                }
            }
        }

        span {
            font-size: 14px;

            @include desktop {
                font-size: 16px;
            }
        }
    }

    .input-control {
        margin-block-start: 0 !important;
    }
}
