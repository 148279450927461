/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

/* stylelint-disable declaration-no-important */

.ProductListWidget {
    padding: 0;

    @include desktop {
        margin-block-end: 20px;
    }

    &-Title {
        margin-block-start: 0;
        text-transform: unset;
        display: none;

        @include mobile {
            text-align: center;
            padding: 0;
            font-size: 24px;
            line-height: 26px;
            margin-block-end: 0;
        }
    
        @include desktop {
            margin-block-end: 10px;
            font-size: 44px;
            line-height: 48px;
            text-align: center;
            display: none;
        }
    }
    
    &-Page {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 20px;

        @include narrow-tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        &_hasTwoProducts {
            display: flex;
            max-width: 900px;
            margin: auto !important;

            @include mobile {
                flex-direction: column;
            }

            @include narrow-tablet {
                flex-direction: row;
            }

            @include desktop {
                .ProductCard {
                    width: 440px;
                }
            }
        }

        &_hasOneProduct {
            display: flex;
            max-width: 400px;
            margin: auto !important;

            .ProductCard {
                width: 440px;
            }
        }
    }
}

.HomePage {
    div[data-content-type*="divider"] {
        hr {
            @include mobile {
                margin-inline: 20px;
                margin-block-end: 20px;
                margin-block-start: 20px;
            }
        }
    }

    .CmsPage-Wrapper {
        h2 {
            text-align: center;

            @include mobile {
                margin-block-end: 0;
            }
        }

        .CATEGORIES-BANNER-WRAPPER {
            h2 {
                margin-block-end: 30px;

                @include mobile {
                    margin-block-end: 15px;
                    font-size: 18px;
                }
            }

            .pagebuilder-column {
                h4 {
                    font-size: 22px;
                    margin-block-end: 0;
                    margin-block-start: 10px;
                    color: var(--h2-color);
                    font-weight: var(--h2-font-weight);
                    text-align: center;
                    font-family: "Roboto-Bold",sans-serif;

                    @include mobile {
                        font-size: 10px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.SlickSliderBlock {
    .slick-list {
        padding-block-start: 18px;
        padding-block-end: 18px;

        @include mobile {
            padding-block-start: 0;
            padding-block-end: 0;
        }
    }

    .slick-slide {
        //padding-inline: 0 10px;

        @include mobile {
            padding-inline: 5px;
        }
    }

    .ProductCard {
        &-ActionsWrapper {
            @include narrow-mobile {
                padding-inline: 5px;
                column-gap: 5px;
            }
        }

        &-Name {
            @include mobile {
                text-overflow: initial;
                white-space: initial;
                font-size: 16px;
                min-height: 68px;
            }
        }

        &-ImageWrapper {
            @include mobile {
                height: 100px;
            }
        }

        &-Content {
            @include mobile {
                padding-inline: 5px;
            }
        }

        .ProductPrice-PriceValue {
            @include mobile {
                font-size: 16px;
            }
        }

        &-AddToCart {
            @include mobile {
                font-size: 0px;
                text-indent: -9999px;
                height: 38px;
                column-gap: 0px;
            }

            :hover {
                border-radius: 5px;
            }
        }

        &-AddToCart.Button:not([disabled]):hover {
            border-radius: 5px;

            @include mobile {
                font-size: 0px;
            }
        }

        &-QuantityWrapper {
            @include narrow-mobile {
                //display: none;
                height: 38px;
            }
        }

        .Image-Image {
            padding: 0px;
        }
    }
}
