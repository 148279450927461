/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

.ProductPage {
    &-Wrapper {
        @include desktop {
            grid-template-columns: 7fr 5fr;
        }

        @include mobile {
            grid-auto-flow: column;
        }
    }

    .ProductLinks-Title {
        @include mobile {
            text-transform: none;
        }
    }
}

.pointer {
    position: absolute;
    z-index: 9;
}
